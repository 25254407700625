import parser from 'html-react-parser'

import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

const Fragment = ({ title, fragments, contrast, merge }) => {
  const [cookies] = useCookies(['neu_nclb_admin'])
  let fragmentString = fragments[title]?.content || `FRAGMENT: "${title}"`
  if (merge) {
    merge.forEach((item) => {
      fragmentString = fragmentString.replace(item.tag, item.content)
    })
  }

  const styles = { backgroundColor: fragments[title] ? 'transparent' : 'pink' }

  const onClickEdit = (e) => {
    e.stopPropagation()
    window.location.href = fragments[title]
      ? `/admin/fragment/${fragments[title].id}/edit`
      : `/admin/fragment/new?fragment[slug]=${title}`
  }

  return fragments[title]?.content || cookies['neu_nclb_admin'] ? (
    <span id={title} style={styles}>
      {parser(fragmentString)}
      {cookies['neu_nclb_admin'] ? (
        <IconButton
          color="secondary"
          style={{ background: 'white' }}
          aria-label="edit"
          onClick={onClickEdit}
          component="span"
        >
          <EditIcon />
        </IconButton>
      ) : (
        ''
      )}
    </span>
  ) : null
}

export default Fragment
