import {
  createEntityAdapter,
  createSlice,
  createReducer,
  configureStore,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import initialLocalState from './initialLocalState'

const adapter = createEntityAdapter()
const sliceNames = ['stories']

function generateSlices(sliceNames) {
  return _.fromPairs(
    _.map(sliceNames, (name) => {
      return [
        name,
        createSlice({
          name,
          initialState: adapter.getInitialState(),
          reducers: {
            upsert: adapter.upsertOne,
            setAll: adapter.setAll,
            remove: adapter.removeOne,
          },
        }).reducer,
      ]
    })
  )
}

export const loadFragments = createAsyncThunk('local/loadFragments', async () => {
  const response = await axios.get('/fragments')
  return response.data
})

const localSlice = createSlice({
  name: 'local',
  initialState: initialLocalState,
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
  extraReducers: {
    [loadFragments.fulfilled]: (state, action) => {
      return { ...state, fragments: action.payload }
    },
  },
})

const donationSlice = createSlice({
  name: 'donation',
  initialState: {
    firstName: null,
    lastName: null,
    optIn: false,
    email: null,
    amount: null,
    paymentIntentId: null
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  }
})

const searchSlice = createSlice({
  name: 'search',
  initialState: { valid: false, postcode: '' },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const store = configureStore({
  reducer: {
    local: localSlice.reducer,
    search: searchSlice.reducer,
    donation: donationSlice.reducer,
    ...generateSlices(sliceNames),
  },
})



export default store
