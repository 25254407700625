import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ImageNavigation from './ImageNavigation'
import placeholder from '../../../../assets/images/neu-placeholder.png'

const useStyles = makeStyles((theme) => ({
  banner: (props) => {
    const extraStyles = window.ENV.IFRAMED
      ? {
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#d60082',
          marginTop: -30,
        }
      : {}

    return {
      width: '100%',
      height: props.noImage ? 'auto' : isWidthUp('md', props.width) ? '60vh' : '40vh',
      marginTop: props.noImage ? 0 : -72,
      position: 'relative',
      backgroundColor: 'transparent',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      ...extraStyles,
    }
  },
  textContainer: (props) => ({
    position: props.noImage ? 'relative' : 'absolute',
    bottom: props.noImage ? 0 : '2rem',
    width: '100%',
  }),
  gradient: (props) => ({
    position: 'absolute',
    height: isWidthUp('md', props.width) ? '60vh' : '40vh',
    width: '100%',
    background:
      'linear-gradient( to bottom, rgba(39, 64, 84, 0) 10%, rgba(39, 64, 84, 0.7) 60%, rgba(39, 64, 84, 1) 95%)',
  }),
  text: (props) => ({
    color: props.light ? theme.palette.primary.main : 'white',
  }),
  nav: {
    position: 'absolute',
    bottom: 0,
    padding: 10,
  },
  navArrow: {
    color: 'white',
  },
  navText: { paddingBottom: 0, fontWeight: 300, fontSize: 14 },
}))

const Banner = (props) => {
  const [cookies] = useCookies(['neu_nclb_admin'])
  const { title, titleExtra, preTitle, slug, noImage, light } = props
  const classes = useStyles({ ...props, noImage, light })
  const [index, setIndex] = useState(null)
  const [valid, setValid] = useState(false)
  const fragments = useSelector((state) => state.local.fragments)

  useEffect(() => {
    const bannerIndex = fragments[slug]?.content
    if (!isNaN(bannerIndex)) setIndex(Number(bannerIndex))
  }, [fragments])

  useEffect(() => {
    setValid(index && !isNaN(index))
  }, [index])

  return (
    <Fade in={!_.isEmpty(fragments)} timeout={1000}>
      <div
        className={classes.banner}
        style={{
          backgroundImage: noImage
            ? 'none'
            : valid
            ? `url('img/banner-${index}-${
                isWidthUp('md', props.width) ? 'desktop' : 'mobile'
              }.jpg')`
            : `url(${placeholder})`,
        }}
      >
        <div className={classes.gradient} />
        <div className={classes.textContainer}>
          {preTitle && (
            <Typography variant="h3" align="center" className={classes.text}>
              {preTitle}
            </Typography>
          )}
          <Typography
            variant="h2"
            align="center"
            color="secondary"
            style={{ lineHeight: '75%' }}
          >
            {titleExtra}
            <br />
            <Typography component="span" variant="h2" className={classes.text}>
              {title}
            </Typography>
          </Typography>
        </div>
        {cookies['neu_nclb_admin'] && !noImage ? (
          <ImageNavigation index={index} updateIndex={setIndex} slug={slug} />
        ) : (
          ''
        )}
      </div>
    </Fade>
  )
}

export default withWidth()(Banner)
