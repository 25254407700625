import '../../../../assets/stylesheets/application.scss'

import InfoIcon from '@material-ui/icons/Info'
import Link from '@material-ui/core/Link'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import neuLogo from '../../../../assets/images/neu-logo.png'
import placeholderLogo from '../../../../assets/images/logo-placeholder.svg'

import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '40px 0',
  },
  footerGrid: (props) => ({
    marginLeft: props.desktop ? -40 : 0,
    marginRight: props.desktop ? -40 : 0,
    paddingLeft: props.desktop ? 20 : 0,
    maxWidth: screen.width,
  }),
  footerSection: (props) => ({
    borderBottom: props.desktop ? 'none' : '1px solid #274054',
    textAlign: 'center',
  }),
  footerLogos: {
    height: 70,
    objectFit: 'contain',
  },
  infoIcon: {
    color: 'white',
    height: 40,
    width: 40,
  },
}))

function Footer(props) {
  const classes = useStyles({ desktop: isWidthUp('md', props.width) })
  const { footer: visible, fragments } = useSelector((state) => state.local)
  const [cookies] = useCookies()
  const admin = cookies['neu_nclb_admin']
  const plUrl = 'partner_logos_url'
  const desktop = isWidthUp('md', props.width)

  const Orgs = ({ partners }) => (
    <Grid item container direction="row" spacing={3} justify="center">
      {partners && (
        <Grid item container xs={6} md={5} lg={4}>
          <Grid
            item
            container
            direction="column"
            spacing={3}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2">Supported By</Typography>
            </Grid>
            <Grid item>
              <a href="/partners">
                <img
                  src={`${fragments[plUrl]?.content}` || placeholderLogo}
                  className={classes.footerLogos}
                  alt="supporter logo"
                />
              </a>
              {admin && (
                <IconButton
                  color="secondary"
                  aria-label="edit"
                  href={
                    !!fragments[plUrl]?.id
                      ? `/admin/fragment/${fragments[plUrl]?.id}/edit`
                      : `/admin/fragment/new?fragment[slug]=${plUrl}`
                  }
                >
                  <EditIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item container xs={6} md={5} lg={4}>
        <Grid
          container
          direction="column"
          spacing={3}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle2">Maintained By</Typography>
          </Grid>
          <Grid item>
            <a href="https://neu.org.uk/" target="_blank">
              <img src={neuLogo} className={classes.footerLogos} alt="maintainer logo" />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const method = (
    <div>
      <InfoIcon className={classes.infoIcon} />
      <Typography variant="subtitle1">
        Find out more about our methodology{' '}
        <Link href="/methodology" color="secondary">
          here
        </Link>
      </Typography>
    </div>
  )

  const legals = (
    <div>
      <Typography variant="body2">© 2021. All rights reserved</Typography>
      <Typography variant="body2">
        <Typography variant="body2" component="span">
          <Link href="/privacy" color="textSecondary" underline="always">
            Privacy Policy
          </Link>
        </Typography>
      </Typography>
    </div>
  )

  return (
    visible && (
      <div id="neu-footer" className={classes.footerRoot}>
        {desktop ? (
          <Grid
            container
            direction="row"
            spacing={5}
            className={classes.footerGrid}
            alignItems="center"
          >
            <Grid item className={classes.footerSection} md={4}>
              {method}
            </Grid>
            <Grid item container className={classes.footerSection} md={4}>
              {Orgs({ partners: false })}
            </Grid>
            <Grid item className={classes.footerSection} md={4}>
              {legals}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" spacing={5} className={classes.footerGrid}>
            <Grid item container className={classes.footerSection}>
              {Orgs({ partners: false })}
            </Grid>
            <Grid item className={classes.footerSection}>
              {method}
            </Grid>
            <Grid item className={classes.footerSection}>
              {legals}
            </Grid>
          </Grid>
        )}
      </div>
    )
  )
}

export default withWidth()(Footer)
