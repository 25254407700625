import { LoremIpsum } from 'lorem-ipsum'
import queryString from 'query-string'
import { ACTION_PETITION_URL } from './constants'

const placeholderCopy = (paragraphs) => {
  const lorem = new LoremIpsum({
    sentencesPerParagraph: {
      max: 8,
      min: 4,
    },
    wordsPerSentence: {
      max: 16,
      min: 4,
    },
  })

  return lorem.generateParagraphs(paragraphs)
}

const validatePostcode = (postcode) =>
  axios
    .get('/api/validate', { params: { postcode } })
    .then((res) => res.status)
    .catch((err) => err.response.status)

const validateLocation = (location) =>
  axios
    .get('/api/validate', { params: { ...location } })
    .then((res) => res.status)
    .catch((err) => err.response.status)

const locationLookup = async (searchString, token) => {
  const parsed = searchString || queryString.parse(location.search)
  let res, params

  if (parsed.postcode) params = _.pick(parsed, ['postcode'])
  else params = _.pick(parsed, ['lat', 'lng'])

  if (token) {
    params.token = token
  }

  res = await axios
    .get('/api/lookup', { params })
    .then((res) => res)
    .catch((err) => err)

  return res.status === 200 ? res.data : false
}

const formValidation = (formRefs, formErrors) => {
  const valid = Object.values(formRefs)[0].current.form.reportValidity()
  if (!valid) {
    const newErrors = Object.assign({}, formErrors)
    Object.keys(formErrors).forEach(
      (key) =>
        (newErrors[key] =
          typeof formRefs[key].current === 'undefined' ||
          !formRefs[key].current.validity.valid)
    )
    return { valid: false, errors: newErrors }
  } else {
    return { valid: true }
  }
}

const andWhatNext = (last, params) => {
  switch (last) {
    case 'results':
      switch (params?.actionType) {
        case 'by_election':
        case 'devolved':
          return '/join'
        case 'petition':
          return `/action?type=petition&postcode=${params?.postcode}`
        case 'email':
          return `/action?type=email&postcode=${params?.postcode}`
        case 'donate':
          return `/donate?postcode=${params?.postcode}`
      }
      break
    case 'storySubmitted':
      return `/action?type=petition`
    case 'storySubmittedAnonymous':
      return `/action?type=petition`
    case 'storyClick':
      return `/donate?postcode=${params?.postcode}`
    case 'getInvolved':
      return `/action?postcode=${params?.postcode}&action=${params?.action}`
    case 'donate':
      if (params?.hasSigned) {
        return `/get_involved?action=share${
          params?.postcode ? '&postcode=' + params?.postcode : ''
        }${params?.post ? '&post=true' : ''}`
      } else {
        return '/action'
      }
    case 'emailMp':
    case 'petition':
      return `/get_involved?action=share${
        params?.postcode ? '&postcode=' + params?.postcode : ''
      }${params?.postcode && params?.post ? '&post=true' : ''}`
  }
}

export {
  placeholderCopy,
  validatePostcode,
  validateLocation,
  locationLookup,
  formValidation,
  andWhatNext,
}
