import Home from '../bundles/ChildPoverty/views/Home/Home'
import { ThemeProvider } from '@material-ui/core/styles'
import muiTheme from '../bundles/ChildPoverty/mui-theme'
import store from '../bundles/ChildPoverty/store'
import { Provider } from 'react-redux'
import Header from '../bundles/ChildPoverty/components/Header'
import Footer from '../bundles/ChildPoverty/components/Footer'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        {window.ENV.IFRAMED === false && <Header />}
        <Home name="React" />
        <Footer />
      </ThemeProvider>
    </Provider>,
    document.getElementById('neu-content').appendChild(document.createElement('div'))
  )
})
