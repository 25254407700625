import '../../../../assets/stylesheets/application.scss'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import Grow from '@material-ui/core/Grow'

import Backdrop from '@material-ui/core/Backdrop'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { MENU_ITEMS } from '../constants'
import logo from '../../../../assets/images/logo.png'
import MenuButton from './MenuButton'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  appBar: (props) => ({
    height: 72,
    backgroundColor: props.opaque ? theme.palette.primary : 'transparent !important',
  }),
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    paddingRight: 0,
  },
  logo: {
    height: 36,
    opacity: 1,
  },
  nav: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    zIndex: 10,
    flexDirection: 'column',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  navClose: {
    position: 'absolute',
    top: 0,
    right: 1,
  },
  navIcons: {
    width: 50,
    height: 50,
    color: 'white',
  },
  featureButton: (props) => ({
    marginRight: theme.spacing(isWidthUp('lg', props.width) ? 2 : 1),
    padding: '4px 20px',
  }),
  buttonRow: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  desktopMenuItem: {
    textTransform: 'capitalize',
    borderBottom: '3px solid transparent',
    borderRadius: 0,
    padding: '4px 0',
    marginRight: 60,
    minWidth: 0,
    '&:hover': {
      borderBottomColor: 'white',
    },
    fontSize: 16,
  },
  desktopMenuItemActive: {
    textTransform: 'capitalize',
    fontSize: 16,
    minWidth: 0,
    borderBottom: '3px solid white',
    borderRadius: 0,
    padding: '4px 0',
    marginRight: 60,
  },
}))

const Header = (props) => {
  const [navOpen, setNavOpen] = useState(false)
  const [cookies] = useCookies(['neu_nclb_admin'])
  const opaque = window.location.pathname.includes('results')
  const classes = useStyles({ ...props, opaque })
  const visible = useSelector((state) => state.local.header)
  const admin = !!cookies['neu_nclb_admin']
  const view = window.location.pathname

  const featureButton = () => (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      className={classes.featureButton}
      href="/join"
    >
      Join the Campaign
    </Button>
  )

  const getAdminButton = (modal) => (
    <Button
      variant={modal ? 'outlined' : 'contained'}
      size={modal ? 'large' : 'small'}
      color={modal ? 'secondary' : 'default'}
      onClick={logoutAdmin}
    >
      Logout
    </Button>
  )

  const logoutAdmin = () =>
    axios
      .delete('/users/sign_out', {
        headers: {
          'X-CSRF-Token': document.querySelector('[name=csrf-token]').content,
        },
      })
      .then(() => window.location.reload())

  return (
    visible && (
      <div id="neu-header" className={classes.root}>
        {/* Navigation menu modal */}
        <Backdrop open={navOpen} classes={{ root: classes.nav }}>
          <Grow in={navOpen}>
            <div className={classes.navClose}>
              <MenuButton onClick={setNavOpen} action="close" />
            </div>
          </Grow>
          <Grid container direction="column" spacing={2} alignItems="center">
            {MENU_ITEMS.map((item) => (
              <Grid item key={item.title} aria-label={item.title}>
                <Button
                  variant="contained"
                  color={item.disabled ? 'default' : 'secondary'}
                  href={item.url}
                  size="large"
                  disabled={item.disabled}
                >
                  {item.title}
                </Button>
              </Grid>
            ))}
            {admin && (
              <Grid item aria-label="logout">
                {getAdminButton(true)}
              </Grid>
            )}
          </Grid>
        </Backdrop>
        {/* Navbar */}
        <AppBar
          position="static"
          elevation={0}
          id="app-appbar"
          classes={{ root: classes.appBar }}
        >
          <Toolbar classes={{ root: classes.toolbar }}>
            <a href="/" className={classes.logo}>
              <img className={classes.logo} src={logo} alt={'logo'} />
            </a>
            <Fade in={!navOpen}>
              {isWidthUp('lg', props.width) ? (
                <div className={classes.buttonRow}>
                  {MENU_ITEMS.map((item) => {
                    if (!item.url.includes('join'))
                      return (
                        <Button
                          variant="text"
                          size="small"
                          color={'primary'}
                          href={item.url}
                          key={item.title}
                          disabled={item.disabled}
                          className={
                            item.url === view
                              ? classes.desktopMenuItemActive
                              : classes.desktopMenuItem
                          }
                          style={item.disabled ? { color: '#505050' } : null}
                        >
                          {item.title}
                        </Button>
                      )
                  })}
                  {admin && getAdminButton()}
                  {featureButton()}
                </div>
              ) : (
                <div>
                  <MenuButton onClick={setNavOpen} action="open" />
                </div>
              )}
            </Fade>
          </Toolbar>
        </AppBar>
      </div>
    )
  )
}

export default withWidth()(Header)
