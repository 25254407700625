import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(1),
    marginTop: -9,
    paddingLeft: 0,
    paddingRight: 0,
    color: 'white',
  },
  menuButtonLabel: {
    flexDirection: 'column',
  },
  menuIconLabel: {
    fontSize: 12,
    marginTop: -7,
  },
}))

const Burger = withStyles({
  root: {
    width: '50px !important',
    height: '50px !important',
  },
})(MenuIcon)

const Close = withStyles({
  root: {
    width: '50px !important',
    height: '50px !important',
  },
})(CloseIcon)

const MenuButton = ({ onClick, action }) => {
  const classes = useStyles()

  return (
    <IconButton
      className={classes.menuButton}
      classes={{ label: classes.menuButtonLabel }}
      color="primary"
      aria-label="menu"
      onClick={() => onClick(action === 'open')}
    >
      {action === 'open' ? <Burger /> : <Close />}
      <div className={classes.menuIconLabel}>{action === 'open' ? 'MENU' : 'CLOSE'}</div>
    </IconButton>
  )
}

export default MenuButton
