import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
  typography: {
    fontFamily: ['brother-1816', 'Arial', '"Helvetica Neue"'].join(','),
    subtitle1: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      [breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '24px',
      },
    },
    subtitle2: {
      color: '#a2b1be',
      fontSize: 12,
    },
    body1: {
      fontSize: 18,
      [breakpoints.down('sm')]: {
        fontSize: 16,
      },
      lineHeight: 1.3,
    },
    body2: {
      color: '#a2b1be',
    },
    h2: {
      fontWeight: 600,
      [breakpoints.down('sm')]: {
        fontSize: 36,
      },
    },
    h3: {
      fontSize: 20,
      paddingBottom: 7,
      textTransform: 'upperCase',
      fontWeight: 600,
      color: 'white',
    },
    h4: {
      fontWeight: 600,
      marginLeft: '5vw',
      marginRight: '5vw',
      [breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    h5: {
      fontSize: 20,
      [breakpoints.down('sm')]: {
        fontSize: 20,
      },
      padding: '5px 0',
      fontWeight: 600,
    },
    h6: {
      fontSize: 22,
      paddingLeft: 15,
      fontWeight: 300,
    },
  },
  palette: {
    text: {
      primary: '#191e24',
      secondary: '#747880',
      default: '#ffffff',
    },
    contrastText: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
    primary: {
      main: '#1c2b3a',
      light: '#f1f4f7',
    },
    secondary: {
      main: '#00a8d7',
    },
  },
  appBar: {
    height: 72,
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
  },
  overrides: {
    MuiButton: {
      root: {
        outline: 'none !important',
        borderRadius: '0 !important',
        height: 46,
        padding: '6px 24px',
      },
      label: {
        fontWeight: 'bold',
        color: 'white !important',
      },
      sizeLarge: {
        height: 56,
        borderRadius: 28,
        fontSize: 16,
        letterSpacing: 1.5,
      },
      sizeSmall: {
        height: 42,
        borderRadius: 21,
        fontSize: 14,
        letterSpacing: 0.5,
      },
      outlinedSecondary: {
        borderColor: 'white',
        color: 'white',
      },
      textSizeSmall: {
        padding: '4px 20px',
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: 30,
        },
      },
    },
    MuiAccordion: {
      root: {
        '&::before': {
          background: 'none',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0 0 10px 0',
      },
    },
    MUIRichTextEditor: {
      root: {
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: '1px 1px 0 1px',
        borderColor: '#c6cfd9',
      },
      container: {
        margin: 0,
      },
      toolbar: {
        padding: 3,
      },
      editor: {
        backgroundColor: 'white',
        padding: 20,
        borderTop: '1px solid',
        borderTopColor: '#C6CFD9',
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: '0 !important',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'white',
        border: '1px solid',
        borderRadius: 4,
        borderColor: '#c6cfd9',
        '&.Mui-disabled': {
          backgroundColor: '#ececec',
        },
      },
    },
    MuiFormLabel: {
      root: {
        lineHeight: 1.2,
      },
    },
  },
})

export default responsiveFontSizes(theme, { factor: 1.5 })
