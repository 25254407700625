import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { N_BANNERS } from '../../constants'

const useStyles = makeStyles(() => ({
  nav: {
    position: 'absolute',
    bottom: 0,
    padding: 10,
  },
  navArrow: {
    color: 'white',
  },
  navText: { paddingBottom: 0, fontWeight: 300, fontSize: 14 },
}))

const ImageNavigation = ({ index, updateIndex, slug, style }) => {
  const classes = useStyles()
  const fragments = useSelector((state) => state.local.fragments)

  const onNavigate = (direction) => {
    let newIndex
    if (!index || isNaN(index)) newIndex = 1
    else
      newIndex =
        direction === 'forward'
          ? index < N_BANNERS
            ? index + 1
            : 1
          : index === 1
          ? N_BANNERS
          : index - 1
    updateIndex(newIndex)

    const form = {
      'fragment[slug]': slug,
      'fragment[content]': newIndex,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
      return_to: '/',
    }
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key])
    })

    if (fragments[slug]) {
      axios.put(`/admin/fragment/${fragments[slug]?.id}/edit`, formData)
    } else {
      axios.post(`/admin/fragment/new`, formData)
    }
  }

  return (
    <Grid
      container
      direction="row"
      className={classes.nav}
      justify="flex-end"
      alignItems="center"
      spacing={1}
      style={style}
    >
      <Grid item>
        <Typography variant="h3" className={classes.navText}>
          {index && !isNaN(index) ? `Banner ${index}` : 'placeholder'}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          aria-label="back"
          onClick={() => onNavigate('back')}
          className={classes.navArrow}
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          aria-label="forward"
          onClick={() => onNavigate('forward')}
          className={classes.navArrow}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ImageNavigation
