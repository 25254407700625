import { validatePostcode } from '../../helpers'
import { ERROR_MESSAGES } from '../../constants'

export const onSearch = async ({ destination, searchState, dispatch }) => {
  if (searchState.postcode) {
    const status = await validatePostcode(searchState.postcode)
    switch (status) {
      case 200:
        /* Now forward to results with postcode */
        window.location.href = `/${destination}?postcode=${searchState.postcode}`
        break
      case 204:
        dispatch({
          type: 'search/upsert',
          payload: { error: true, errorMsg: ERROR_MESSAGES.postcodeNoData },
        })
        break
      case 404:
        dispatch({
          type: 'search/upsert',
          payload: { error: true, errorMsg: ERROR_MESSAGES.postcodeInvalid },
        })
        break
    }
  } else {
    /* Button clicked with empty input */
    dispatch({
      type: 'search/upsert',
      payload: { error: true, errorMsg: ERROR_MESSAGES.postcodeEmpty },
    })
  }
}
