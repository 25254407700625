import useTheme from '@material-ui/core/styles/useTheme'

const Division = ({ narrow, padding, white, left }) => {
  const theme = useTheme()
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: left ? 'start' : 'center',
        padding: `${padding || '0'}px 0`,
      }}
    >
      <div
        style={{
          height: narrow ? 1 : 5,
          width: narrow ? '100%' : 30,
          backgroundColor: white
            ? 'white'
            : narrow
            ? '#4b576e'
            : theme.palette.secondary.main,
          borderRadius: narrow ? 0 : 2,
        }}
      />
    </div>
  )
}

export default Division
