import Fragment from '../../components/Fragment'
import Division from '../../components/Division'

const HomeSection = ({ id, children, fragments, contrast, noPara }) => {
  return (
    <Grid
      container
      spacing={3}
      alignContent="center"
      justify="center"
      {...{ style: { position: 'relative' } }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          <Fragment title={`home_section_${id}_title`} {...{ fragments, contrast }} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Division white={contrast} />
      </Grid>
      {!noPara && (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            <Fragment
              title={`home_section_${id}_paragraph`}
              {...{ fragments, contrast }}
            />
          </Typography>
        </Grid>
      )}
      {children}
    </Grid>
  )
}

export default HomeSection
