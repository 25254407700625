import Input from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import LocationDisabledIcon from '@material-ui/icons/LocationDisabled'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Grow from '@material-ui/core/Grow'
import { validateLocation } from '../helpers'
import { ERROR_MESSAGES } from '../constants'

const styles = {
  input: {
    backgroundColor: 'white',
  },
  inputButton: {
    color: 'grey',
  },
  locationButton: {
    marginRight: -8,
  },
}

/**
 *  Redux-connected component: input state not stored in-component
 * @param focus - autofocus the input element on component mount
 * @param onSubmit - function callback for pressing enter key
 * @param helperText
 * @returns {JSX.Element}
 * @constructor
 */
const PostcodeInput = ({ focus, onSubmit, helperText }) => {
  const [locationPermission, setLocationPermission] = useState(false)
  const [locationHelpDialog, setLocationHelpDialog] = useState(false)
  const [key] = useState(Date.now())
  const dispatch = useDispatch()
  const searchState = useSelector((state) => state.search)
  const postcodeInput = useRef(key)

  useEffect(() => postcodeInput.current && focus && postcodeInput.current.focus(), [])
  useEffect(() => checkLocationPermission(), [])

  const checkLocationPermission = () => {
    if (!!navigator.permissions && 'geolocation' in navigator) {
      setLocationPermission('unavailable')
      navigator.permissions
        ?.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          setLocationPermission(permissionStatus.state)
        })
    } else {
      setLocationPermission('unavailable')
    }
  }

  const onLocate = () => {
    if (locationPermission !== 'denied') {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          checkLocationPermission()

          /* Check location for available data */
          validateLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }).then((status) => {
            if (status === 403) {
              dispatch({
                type: 'search/upsert',
                payload: { error: true, errorMsg: ERROR_MESSAGES.locationOutsideUk },
              })
            } else {
              /* Now send user to results page with coords */
              window.location.href = `/results?lat=${position.coords.latitude.toFixed(
                6
              )}&lng=${position.coords.longitude.toFixed(6)}`
            }
          })
        },
        (positionError) => {
          checkLocationPermission()
        }
      )
    } else {
      setLocationHelpDialog(true)
    }
  }

  const onSearch = async (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const update = (e) =>
    dispatch({
      type: 'search/upsert',
      payload: { postcode: e.target.value, error: false, errorMsg: '' },
    })

  return (
    <div>
      <Dialog
        open={locationHelpDialog}
        onClose={() => setLocationHelpDialog(false)}
        aria-labelledby={'alert-dialog-title' + key}
        aria-describedby={'alert-dialog-description' + key}
      >
        <DialogTitle id={'alert-dialog-title' + key}>
          {'Browser location disabled'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={'alert-dialog-description' + key}>
            If you would like to use the location feature, please reset your browser
            location permissions for this website.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLocationHelpDialog(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <FormControl variant="outlined" fullWidth>
        <Input
          id={'postcode-input-' + key}
          key={'postcode-input-' + key}
          inputRef={postcodeInput}
          style={styles.input}
          placeholder="Postcode"
          onKeyPress={onSearch}
          onChange={update}
          error={searchState.error}
          value={searchState.postcode}
          name="postcode"
          autoComplete="postal-code"
          aria-describedby={'postcode-helper-text' + key}
          endAdornment={
            locationPermission !== 'unavailable' && (
              <InputAdornment position="end">
                <IconButton style={styles.locationButton} onClick={onLocate}>
                  {locationPermission === 'denied' ? (
                    <LocationDisabledIcon style={styles.inputButton} />
                  ) : (
                    <MyLocationIcon style={styles.inputButton} />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }
        />
        <Grow in={searchState.error || !!helperText}>
          <FormHelperText id={'postcode-helper-text' + key} error={searchState.error}>
            {searchState.error ? searchState.errorMsg : helperText}
          </FormHelperText>
        </Grow>
      </FormControl>
    </div>
  )
}

export default PostcodeInput
