import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

export const useStyles = makeStyles(() => ({
  container: ({ white }) => ({
    background: white ? 'transparent' : 'inherit',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: white ? 'white' : 'black',
    borderRadius: 0,
    marginBottom: 15,
  }),
  content: ({ white }) => ({
    color: white ? 'white' : 'inherit',
  }),
  summary: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: ({ white, width }) => ({
    color: white ? 'white' : 'inherit',
    fontWeight: 500,
    fontSize: isWidthUp('md', width) ? 'inherit' : 14,
  }),
  number: ({ white }) => ({
    fontWeight: 900,
    marginRight: 24,
    color: white ? 'white' : 'inherit',
  }),
  arrow: ({ white }) => ({
    color: white ? 'white' : 'inherit',
  }),
}))

const PledgeStep = ({ code, title, content, white, width }) => {
  const classes = useStyles({ white, width })
  return (
    <Accordion elevation={0} classes={{ root: classes.container }}>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon className={classes.arrow} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.summary}
      >
        <Typography className={classes.title}>
          <Typography component="span" className={classes.number}>
            {code}
          </Typography>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" classes={{ root: classes.content }}>
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default withWidth()(PledgeStep)
