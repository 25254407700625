import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import '../../../../../assets/stylesheets/application.scss'
import PostcodeInput from '../../components/PostcodeInput'
import Fragment from '../../components/Fragment'
import { loadFragments } from '../../store'
import Banner from '../../components/Banner'
import { useStyles } from './styles'
import { onSearch } from './helpers'
import Pledges from './Pledges'
import HomeSection from './HomeSection'
import { Typography } from '@material-ui/core'

const Home = ({ width }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadFragments())
  }, [])

  const searchState = useSelector((state) => state.search)
  const fragments = useSelector((state) => state.local.fragments)
  const onClickSearch = (destination) => onSearch({ destination, searchState, dispatch })

  const getSearchSection = ({ id, focus, destination, contrast }) => {
    const buttonPad = isWidthUp('md', width) ? '14vw' : '4vw'
    const postcodePad = isWidthUp('md', width) ? '10vw' : '4vw'
    return (
      <HomeSection {...{ id, fragments, contrast }}>
        <Grid
          item
          xs={12}
          style={{ paddingRight: postcodePad, paddingLeft: postcodePad }}
        >
          <PostcodeInput focus={focus} onSubmit={() => onClickSearch(destination)} />
        </Grid>
        <Grid item xs={12} style={{ paddingRight: buttonPad, paddingLeft: buttonPad }}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => onClickSearch('results')}
          >
            <Fragment title={`home_section_${id}_button`} {...{ fragments }} />
          </Button>
        </Grid>
      </HomeSection>
    )
  }

  const getPledgeSection = () => (
    <HomeSection {...{ id: 2, fragments }} noPara>
      <Grid item xs={12} sm={10} md={8} lg={6} style={{ width: '100%' }}>
        <div>
          <Typography variant="body1" component="div">
            <Fragment title="home_pledge_text" fragments={fragments} />
          </Typography>
        </div>
      </Grid>
    </HomeSection>
  )

  // const getFragmentSection = () => (
  //   <Grid container direction="column" spacing={2} alignContent="center">
  //     <Grid item xs={12} md={8} lg={6}>
  //       <Typography variant="body1" align="center" component="div">
  //         <Fragment title="home_section_4_paragraph" {...{ fragments }} />
  //       </Typography>
  //     </Grid>
  //   </Grid>
  // )

  return (
    <Fade in={!_.isEmpty(fragments)}>
      <Container maxWidth={false} disableGutters>
        <Banner title="LEFT BEHIND" titleExtra="NO CHILD" slug={'home_banner_image'} />

        <Grid container direction="column">
          <Grid item xs={12} className={classes.gridOne}>
            {getSearchSection({ id: 1, focus: true, destination: 'results' })}
          </Grid>
          <Grid item container xs={12} className={classes.gridTwo}>
            {getPledgeSection()}
          </Grid>
          <Grid item xs={12} className={classes.gridThree}>
            {getSearchSection({ id: 3, destination: 'action', contrast: true })}
          </Grid>
        </Grid>
      </Container>
    </Fade>
  )
}

export default withWidth()(Home)
