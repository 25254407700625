import stockChildren from '../../../../../assets/images/child-stock.jpg'

export const useStyles = makeStyles((theme) => ({
  gridOne: {
    backgroundColor: theme.palette.primary.light,
    padding: '40px 5vw 60px 5vw',
  },
  gridTwo: {
    backgroundColor: '#ffffff',
    padding: '40px 5vw 50px 5vw',
  },
  gridThree: {
    padding: '40px 5vw 75px 5vw',
    position: 'relative',
    '&::before': {
      content: `""`,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      position: 'absolute',
      background: `url(${stockChildren})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      filter: 'contrast(10%) sepia(100%) hue-rotate(145deg) brightness(1) saturate(3.28)',
    },
  },
  gridFour: {
    backgroundColor: '#ffffff',
    padding: '60px 5vw 70px 5vw',
  },
}))
