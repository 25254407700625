const STATS_DISPLAY_TYPE = process.env.STATS_DISPLAY_TYPE
const ACTION_PETITION_URL = process.env.ACTION_PETITION_URL
const SELECT_UNIONS = process.env.SELECT_UNIONS.split(',')
const SELECT_SCHOOL_ROLES = process.env.SELECT_SCHOOL_ROLES.split(',')
const N_BANNERS = process.env.N_BANNERS
const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY
const DONATE_AMOUNTS = process.env.DONATE_AMOUNTS || '10,60,120,600'
const URL_NOT_RIGHT_NOW = '/'
const SHOW_STORIES = false
const CTA_TYPE = 'donate'
const SHOW_PRE_ACTION = false

const MENU_ITEMS = [
  { title: 'home', url: '/' },
  { title: 'about', url: '/about' },
  // { title: 'donate', url: '/donate' },
  { title: 'share your story', url: '/story' },
  { title: 'join the campaign', url: '/join' },
  // { title: 'partners', url: '/partners' },
  // { title: 'blog', url: '/#', disabled: true },
]

const ERROR_MESSAGES = {
  postcodeInvalid: 'Please enter a full valid postcode',
  postcodeEmpty: 'Please click location icon or enter a postcode',
  postcodeNoData: 'We have no data for this postcode - sorry!',
  emailInvalid: 'Please enter a valid email',
  nameEmpty: 'Please enter a name',
  storyEmpty: 'Please write something here',
  locationOutsideUk: "You're outside the UK!",
  fieldEmpty: 'Please fill this in',
  amountInvalid: 'Please enter more than 0',
}

const FORM_LABELS = {
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email address',
  postcode: 'Post Code',
  body: 'Email content',
  subject: 'Email subject',
  story: 'Your story',
  phone_number: 'Mobile',
  address: 'Address',
  town: 'Town/city',
  amount: 'Another amount',
}

const FORM_PROPS = {
  first_name: { type: 'text', autoComplete: 'given-name' },
  last_name: { type: 'text', autoComplete: 'family-name' },
  email: { type: 'email', autoComplete: 'email' },
  postcode: { type: 'text', autoComplete: 'postal-code' },
  phone_number: { type: 'tel', autoComplete: 'tel' },
  union_member: { options: SELECT_UNIONS, label: 'Which union?' },
  school_role: {
    options: SELECT_SCHOOL_ROLES,
    label: 'If you work at a school, or are a parent, let us know',
  },
}

const FORM_ERROR_TEXT = {
  first_name: ERROR_MESSAGES.nameEmpty,
  email: ERROR_MESSAGES.emailInvalid,
  last_name: ERROR_MESSAGES.nameEmpty,
  postcode: ERROR_MESSAGES.postcodeInvalid,
  body: '',
  subject: '',
  story: ERROR_MESSAGES.storyEmpty,
  phone_number: ERROR_MESSAGES.fieldEmpty,
  address: ERROR_MESSAGES.fieldEmpty,
  town: ERROR_MESSAGES.fieldEmpty,
  amount: ERROR_MESSAGES.amountInvalid,
}

const FORMS = {
  join: {
    fields: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      postcode: '',
      union_member: '',
      school_role: '',
      opt_in: null,
    },
  },
  signup: {
    fields: { first_name: '', email: '' },
  },
  donation: {
    fields: { amount: null },
  },
  donationSignup: {
    fields: {
      first_name: '',
      last_name: '',
      email: '',
      opt_in: null,
      amount: null,
    },
  },
  petition: {
    fields: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      postcode: '',
      union_member: '',
      school_role: '',
      opt_in: null,
    },
  },
  emailMp: {
    fields: {
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      postcode: '',
      body: '',
      subject: '',
      opt_in: null,
    },
  },
  story: {
    fields: {
      story: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      address: '',
      town: '',
      postcode: '',
    },
  },
}

export {
  MENU_ITEMS,
  ERROR_MESSAGES,
  FORMS,
  FORM_LABELS,
  FORM_ERROR_TEXT,
  FORM_PROPS,
  N_BANNERS,
  GOOGLE_API_KEY,
  URL_NOT_RIGHT_NOW,
  DONATE_AMOUNTS,
  SHOW_STORIES,
  SHOW_PRE_ACTION,
  CTA_TYPE,
  STATS_DISPLAY_TYPE,
  ACTION_PETITION_URL,
  SELECT_UNIONS,
  SELECT_SCHOOL_ROLES,
}
